import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { StyledTippy } from '../../layout/mixins'
import { DOMAIN } from '../../config'

const LinkButton = styled.button`
  text-decoration: underline;
`

const CopyLink = ({
  path, placement, text, onCopiedText
}) => {
  const copyLink = useRef()
  const [copied, setCopied] = useState(false)
  return (
    <>
      <CopyToClipboard onCopy={() => setCopied(true)} text={`${DOMAIN}${path}`}><LinkButton ref={copyLink}>{text}</LinkButton></CopyToClipboard>
      <StyledTippy
        placement={placement}
        arrow={false}
        content={onCopiedText}
        visible={copied}
        getReferenceClientRect={() => copyLink.current.getBoundingClientRect()}
        onShow={() => {
          setTimeout(() => {
            setCopied(false)
          }, 1000)
        }}
      />
    </>
  )
}

export default CopyLink

CopyLink.propTypes = {
  path: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  text: PropTypes.string,
  onCopiedText: PropTypes.string
}

CopyLink.defaultProps = {
  placement: 'right',
  text: 'Kopiera länk',
  onCopiedText: 'Kopierad!'
}