import styled from 'styled-components'
import { ContentAlign, ParagraphSmallStyles, breakpoint } from '../../layout/mixins'
import Arrow from '../../layout/icons/long-icon-right.svg'

export const Article = styled(ContentAlign)`
  padding-top: ${({ theme }) => theme.gutter};
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    padding-top: ${({ theme }) => theme.gutterXXLarge};
    padding-bottom: ${({ theme }) => theme.gutterXXLarge};
  `}
`

export const ArticleHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${({ theme }) => theme.gutter};
  grid-row-gap: ${({ theme }) => theme.gutterMedium};

  ${breakpoint.up('phone')`
    grid-template-columns: 1fr 1fr;
  `}
`

export const AuthorsList = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ArticleInformation = styled.div`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-size: ${({ theme }) => theme.fontsizeSmall};

  p {
    padding-top: 0;
  }
  p + p {
    padding-top: 1em;
  }
`

export const AuthorsListItem = styled.li`
  width: 50%;
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.fontsizeSmall};
  &:before {
    content: '↓';
    display: block;
    font-family: ${({ theme }) => theme.fontfamilySecondary};
    font-size: ${({ theme }) => theme.fontsizeSmall};
    padding-right: ${({ theme }) => theme.gutterSmall};
  }
`
export const ArticlePreamble = styled.div`
  font-size: ${({ theme }) => theme.heading3FontSize}; 
  padding-top: ${({ theme }) => theme.gutterXXLarge};
`

export const Texts = styled.article`
`


export const ArticleFooter = styled.footer`
  padding-top: ${({ theme }) => theme.gutterXXLarge};
  ${breakpoint.up('phone')`
    padding-top: calc(${({ theme }) => theme.gutterXXLarge} * 2);
  `}
`

export const LinksList = styled.ul`
  border-bottom: 1px solid ${({ theme }) => theme.colorAccent};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const LinkItem = styled.li`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme }) => theme.fontsizeSmall};
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    padding-right: ${({ theme }) => theme.gutterMedium};
  `}
`
export const LinkArrow = styled(Arrow)`
  fill: ${({ theme }) => theme.colorAccent};
  margin-right: ${({ theme }) => theme.gutter};
  width: 23px;
  height: 8px;
`
export const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, 308px);
  column-gap: ${({ theme }) => theme.gutter};
  row-gap: ${({ theme }) => theme.gutter};
  margin-top: ${({ theme }) => theme.gutterLarge};
  margin-bottom: ${({ theme }) => theme.gutterXXLarge};

  ${breakpoint.up('phone')`
    grid-template-columns: repeat(2, minmax(427px, 1fr));
    grid-template-rows: repeat(1, 308px);
  `}
`