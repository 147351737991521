import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Modal from '../Modal'
import {
  Content, StyledButton, Disclaimer, Error, StatusText, Email, StyledDropdown, Name, TextArea
} from './styles'
import {
  ArrowDown, ArrowUp
} from '../../layout/mixins'
import { encodeObject } from '../../utils/helpers'

const ContactModal = ({ toggle }) => {
  const emailRef = useRef()
  const formRef = useRef()
  const [information, setinformation] = useState({
    email: '',
    office: false,
    name: '',
    message: ''
  })
  const [invalid, setInvalid] = useState(true)
  const [status, setStatus] = useState('form')
  const options = [
    'Stockholm', 'Göteborg', 'Linköping', 'Malmö', 'Skövde'
  ]
  const defaultOption = information.office

  const onChange = (name) => (event) => {
    const { value } = event.target || event
    setinformation((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    const body = encodeObject({
      ...information,
      'form-name': 'contact'
    })

    try {
      fetch('/', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body
      })
        .then((response) => { setStatus('success') })
        .catch((response) => { setStatus('error') })
    } catch (error) {
      setStatus('error')
    }
  }

  useEffect(() => {
    setInvalid(!(formRef.current.checkValidity() && information.office))
  }, [information])

  const FormMessage = () => {
    if (status === 'error') {
      return (
        <Content>
          <StatusText>
            <Error>Något gick fel.</Error>
            <p>Vi kunde inte skicka iväg ditt meddelande Prova igen senare.</p>
          </StatusText>
        </Content>
      )
    }
    if (status === 'success') {
      return (
        <Content>
          <StatusText><p>Tack! Vi har fått ditt meddelande nu och kommer höra av oss inom kort.</p></StatusText>
        </Content>
      )
    }
    return null
  }

  return (
    <Modal
      toggle={toggle}
    >
      <form onSubmit={onSubmit} ref={formRef}>
        <FormMessage />
        { status === 'form' && (
        <Content>
          <StyledDropdown
            options={options}
            value={defaultOption}
            placeholder="Välj kontor"
            arrowClosed={<ArrowDown />}
            arrowOpen={<ArrowUp />}
            onChange={onChange('office')}
          />
          <Name
            onChange={onChange('name')}
            value={information.name}
            type="text"
            required
            placeholder="Namn"
            valid={!invalid}
          />
          <Email
            ref={emailRef}
            onChange={onChange('email')}
            value={information.email}
            type="email"
            required
            placeholder="E-post"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            valid={!invalid}
          />
          <TextArea
            onChange={onChange('message')}
            value={information.message}
            placeholder="Eventuellt meddelande"
          />
          <StyledButton disabled={invalid} type="submit" text="Skicka" arrow />
          <Disclaimer>
            Genom att skicka iväg dina kontaktuppgifter godkänner du våra
            {' '}
            <Link to="/cookies">villkor</Link>
            .
          </Disclaimer>
        </Content>
        )}

      </form>
    </Modal>
  )
}

export default ContactModal

ContactModal.propTypes = {
  toggle: PropTypes.func.isRequired
}