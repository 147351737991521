import styled from 'styled-components'
import Dropdown from 'react-dropdown'
import Button from '../Button'
import {
  breakpoint, ContentAlign, ParagraphStyles, InputStyles, DropdownStyles
} from '../../layout/mixins'
import 'react-dropdown/style.css'

export const Content = styled(ContentAlign)`
  display: grid;
  
  row-gap: ${({ theme }) => theme.gutter};
  column-gap: ${({ theme }) => theme.gutter};
  padding-top: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    grid-template-columns: [input] minmax(auto, 345px) [button] minmax(auto, 150px);
  `}
`

export const StyledButton = styled(Button)`
  grid-column: button / button;
  align-self: end;
`

export const Disclaimer = styled.p`
  font-size: ${({ theme }) => theme.fontsizeSmall};
  line-height: ${({ theme }) => theme.lineheightRegular};
  grid-column: input / button;
`
export const Error = styled.p`
  color: ${({ theme }) => theme.colorDanger};
`

export const StatusText = styled.div`
  ${ParagraphStyles}
  grid-column: input / button;
  font-size: ${({ theme }) => theme.heading3FontSize};
`

export const Email = styled.input`
  ${InputStyles}
  grid-column: input / input;
`
export const Name = styled.input`
  ${InputStyles}
  grid-column: input / input;
`

export const StyledDropdown = styled(Dropdown)`
  ${DropdownStyles}
  grid-column: input / input;
`
export const TextArea = styled.textarea`
  ${InputStyles}
  grid-column: input / input;
`