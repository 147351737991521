import styled from 'styled-components'
import { Heading2Styles, Heading4Styles, breakpoint } from '../../layout/mixins'
import AuthorImage from '../AuthorImage'

export const Container = styled.article`
  display: grid;
  grid-template-columns: [portrait] ${({ theme }) => theme.portraitSize} [text] 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: ${({ theme }) => theme.gutter};
  grid-row-gap: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    grid-row-gap: 0;
    grid-template-rows: ${({ theme }) => theme.portraitSize} auto;
  `}
`

export const Heading = styled.h2`
  line-height: ${({ theme }) => theme.fontsizeRoot};
  align-self: center;
`
export const Name = styled.span`
  ${Heading2Styles}
  margin: 0 calc(${({ theme }) => theme.gutter} / 2) 0 0;
  display: block;
  line-height: 1.3;
  ${breakpoint.up('phone')`
    display: inline-block;  
  `}
`

export const Title = styled.span`
  ${Heading4Styles}

`
export const Columns = styled.div`  
  grid-column: span 2;

  ${breakpoint.up('phone')`
    grid-column: text / text;
    columns: 2;
    column-gap: ${({ theme }) => theme.gutter};
  `}

`

export const Seperator = styled.div`
  height: 1px;
  border-top: 1px solid ${({ theme }) => theme.colorAccent};
  margin: ${({ theme }) => theme.gutterXXLarge} 0;
`

export const StyledAuthorImage = styled(AuthorImage)`
  align-self: center;
`
