import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Container, Heading, Name, Title, Columns, Seperator, StyledAuthorImage
} from './styles'
import { propTypes as AuthorImagePropTypes } from '../AuthorImage'

const AuthorCommentary = ({
  id, author, text, seperator
}) => {
  const {
    image,
    name,
    title,
    company
  } = author
  return (
    <>
      { seperator ? (
        <Seperator />
      ) : null}
      <Container id={id}>
        <StyledAuthorImage image={image} />
        <Heading>
          <Name>{name}</Name>
          <Title>
            {title}
            ,
            {' '}
            {company}
          </Title>
        </Heading>
        <Columns className="rte" dangerouslySetInnerHTML={{ __html: text }} />
      </Container>
    </>
  )
}

export default AuthorCommentary

AuthorCommentary.propTypes = {
  id: PropTypes.string,
  author: PropTypes.shape({
    ...AuthorImagePropTypes,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
  seperator: PropTypes.bool
}

AuthorCommentary.defaultProps = {
  id: null,
  seperator: false
}

export const query = graphql`
  fragment AuthorCommentaryFragment on DatoCmsTextContent {
    author {
      title
      name
      company
      image {
        ...AuthorImageFragment
      }
    }
    text
  }
`
