import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import AuthorCommentary from '../../components/AuthorCommentary'
import { Article, ArticleHeader, ArticleFooter, ArticleInformation, AuthorsList, AuthorsListItem, ArticlePreamble, Texts, LinkArrow, LinksList, LinkItem, ArticlesGrid } from './styles'
import slugify from 'slugify'
import scrollToElement from 'scroll-to-element'
import ArticleNumber from '../../components/ArticleNumber'
import CopyLink from '../../components/CopyLink'
import Toggle from '../../components/Toggle'
import SubscribeModal from '../../components/SubscribeModal'
import ContactModal from '../../components/ContactModal'
import ArticleCard from '../../components/ArticleCard'

const SingleArticle = ({ data, path }) => {
  const {
    heroImage,
    title,
    textContent: texts,
    preamble,
    seoMetaTags,
    meta: {
      publishedAt
    },
    id,
  } = data?.content

  const { articles } = data

  const [openSubscribeModal, SetOpenSubscribeModal] = useState(0)

  const toggleSubscribeModal = () => {
    SetOpenSubscribeModal(prev => {
      return !prev
    })
  }

  const formattedpublishedAt = new Intl.DateTimeFormat('sv-SE', {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  }).format(new Date(publishedAt))

  const authors = texts.map((item) => item.author)
  const readingTime = Math.ceil(texts.map((text) => text.text)
                            .join(' ')
                            .replace(/[^a-öA-Ö ]/gi, '')
                            .split(' ')
                            .filter(Boolean).length / 200)


  const scrollToAuthor = (slug) => {
    const element = document.getElementById(slug)

    if (element) {
      scrollToElement(element, {
        offset: -document.getElementById('header').offsetHeight
      })
    }
  }
  const position = <ArticleNumber id={id} />

  return (
    <>
    <Layout seo={seoMetaTags.tags} progressBar={true}>
      <Hero 
        backgroundImage={heroImage}
        title={title}
        indentTitle={position}
       />
      <Article as="article">
        <ArticleHeader>
          <ArticleInformation>
            <p><time dateTime={publishedAt}>{formattedpublishedAt}</time> | Lästid: {readingTime} minuter</p>
            <p>Dela: <CopyLink path={path} /></p>
          </ArticleInformation>

          { authors ? (
          <AuthorsList>
            {authors.map(author => {
              const slug = slugify(author?.name, {lower: true})
              return (
                <AuthorsListItem key={author.name}>
                  <a 
                    href={`#${slug}`}
                    onClick={(event) => {
                      event.preventDefault()
                      scrollToAuthor(slug)
                    }}>
                    {author.name}
                  </a>
                </AuthorsListItem>
              )
              })}
            
          </AuthorsList>
          ) : null}
        </ArticleHeader>
        <ArticlePreamble className="rte rte--large" dangerouslySetInnerHTML={{ __html: preamble }} />
        <Texts>
        { texts ? (
            texts.map((text, index) => {
              const id = slugify(text?.author?.name, {lower: true})
              return (
                <AuthorCommentary
                  key={id}
                  id={id}
                  seperator={true}
                  {...text}
                />
              )
            })
        ) : null}
        </Texts>
        <ArticleFooter>
          <LinksList>
            <LinkItem>
              <LinkArrow /><p>Dela: <CopyLink placement="top" path={path} /></p>
            </LinkItem>
            <LinkItem>
              <LinkArrow />
              <Toggle>
                {(on, toggle) => (
                  <>
                    <button type="button" data-cy="toggle-subscribe" onClick={toggle}>Meddela mig när nästa artikel publiceras</button>
                    {on && (
                      <SubscribeModal
                        toggle={toggle}
                      />
                    )}
                  </>
                )}
              </Toggle>
            </LinkItem>
            <LinkItem>
              <LinkArrow />
              <Toggle>
                {(on, toggle) => (
                  <>
                    <button type="button" data-cy="toggle-contact" onClick={toggle}>Hör av dig!</button>
                    {on && (
                      <ContactModal
                        toggle={toggle}
                      />
                    )}
                  </>
                )}
              </Toggle>
            </LinkItem>
          </LinksList>
            { articles.edges.length === 2 ? (
              <ArticlesGrid>
              {articles.edges.map(({ node }) => (
                <ArticleCard
                  key={node.slug}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...node}
                />
              ))}
            </ArticlesGrid>
            ) : null }
        </ArticleFooter>
      </Article>
    </Layout>
    </>
  )
}

export default SingleArticle

export const query = graphql`
  query SingleArticleQuery($slug: String!) {
    content: datoCmsArticle(slug: {eq: $slug}) {
      id
      title
      heroImage {
        fluid(maxWidth: 2048, imgixParams: { fm: "jpg", auto: "compress" }, resizes: [375, 750, 850, 1024, 1700, 2048]) {
          ...GatsbyDatoCmsFluid
        }
      }
      textContent {
        ...AuthorCommentaryFragment
      }
      meta {
        publishedAt
        status
      }
      preamble
      seoMetaTags {
        tags
      }
    }
    articles: allDatoCmsArticle(filter: {meta: {status: {ne: "draft"}}, slug: {ne: $slug}}, limit: 2) {
      edges {
        node {
          id
          slug
          title
          meta {
            status
          }
          thumbnail {
            fluid(maxWidth: 2048, imgixParams: { fm: "jpg", auto: "compress" }, resizes: [375, 750, 850, 1024, 1700, 2048]) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
//articles: allDatoCmsArticle(filter: {meta: {status: {ne: "draft"}}}) {